import { LosseLink } from '@ubo/losse-sjedel'
import Logo from '~/components/svg/Logo'
import HeaderMenu from './HeaderMenu'

export default function HeaderDesktop() {
  return (
    <nav className="hidden lg:block bg-black-knight border-b-[1px] border-b-white border-opacity-30">
      <div className="container-xl flex items-center justify-between">
        <LosseLink aria-label="Home" to="/">
          <Logo className="w-full" />
        </LosseLink>
        <ul className="relative hidden w-full lg:flex lg:items-center lg:justify-end">
          <HeaderMenu />
        </ul>
      </div>
    </nav>
  )
}
