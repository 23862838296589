export default function ChevronDark({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" fill="none" viewBox="0 0 6 11" className={className}>
      <path
        fill="#000"
        d="M5.99 1.02a.75.75 0 00-.113-.38.678.678 0 00-.285-.255.623.623 0 00-.705.12L.233 4.778a.71.71 0 00-.172.243.757.757 0 000 .598.71.71 0 00.172.244l4.654 4.279a.656.656 0 00.227.146.622.622 0 00.518-.034.674.674 0 00.209-.175.763.763 0 00.08-.803.704.704 0 00-.171-.218L1.683 5.321 5.75 1.584a.71.71 0 00.18-.253.758.758 0 00.06-.311z"
      ></path>
    </svg>
  )
}
