import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import MagnifyingGlass from '~/components/svg/MagnifyingGlass'
import type { HeaderLoaderData } from './Header'

export default function HeaderTopmenu() {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <div className="relative z-[100] bg-oil-rush py-3">
      <div className="container-xl flex justify-between">
        <ul className="text-2xs lg:text-xs hidden md:flex">
          {header?.topmenuHeader?.usps?.map((usp, index: number) => (
            <li key={index} className="flex items-center mr-4 lg:mr-12">
              <LossePlaatjie src={usp?.icon} className="mb-1" maxWidth={15} />
              <span className="ml-1">{usp?.description}</span>
            </li>
          ))}
        </ul>
        <Slider
          className="md:hidden w-1/2 !mx-0"
          draggable={false}
          allowTouchMove={false}
          autoplay={{
            delay: 3000
          }}
          loop
          direction="horizontal"
          slidesPerView={1}
        >
          {header?.topmenuHeader?.usps?.map((usp, index: number) => (
            <Slide key={index} className="flex items-center">
              <LossePlaatjie src={usp?.icon} className="mb-1" maxWidth={15} />
              <span className="ml-1 text-3xs">{usp?.description}</span>
            </Slide>
          ))}
        </Slider>
        <ul className="flex items-center">
          <li className="lg:mr-5">
            <LosseLink to={header.topmenuHeader?.cta?.link?.url} className="flex items-center text-2xs lg:text-sm hover:underline">
              <LossePlaatjie src={header.topmenuHeader?.cta?.icon} className="mb-1 max-sm:hidden" maxWidth={14} />
              <span className="ml-1 font-light sm:font-bold">{header.topmenuHeader?.cta?.link?.title}</span>
            </LosseLink>
          </li>
          <li>
            <LosseLink to="/zoeken/" className="hidden lg:block hover:opacity-80">
              <MagnifyingGlass className="w-5 h-5" />
            </LosseLink>
          </li>
        </ul>
      </div>
    </div>
  )
}
