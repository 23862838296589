import React from 'react'
import { LosseLink } from '@ubo/losse-sjedel'

export interface ButtonProps extends React.ComponentPropsWithoutRef<any> {
  onClick?: () => void
  to?: string
}

const Button: React.FC<ButtonProps> = ({ children, className = '', ...props }) => {
  return (
    <LosseLink className={`${className} bg-indigo-800 hover:text-white text-white py-2 px-3`} {...props}>
      {children}
    </LosseLink>
  )
}

export default Button
