import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import TextDefault from './TextDefault'
import TextImage from './TextImage'
import TextVideo from './TextVideo'
import TextSpecs from './TextSpecs'

export default function TextShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: TextDefault,
    image: TextImage,
    video: TextVideo,
    specs: TextSpecs
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
