import { LossePlaatjie } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderGallery({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section id="SliderGallery" className="bg-black-knight text-white py-20">
      <div className="container">
        <div className="flex flex-col items-center">
          <Slider
            autoplay={{
              delay: 5000
            }}
            pagination={{ el: '.swiper-pagination', clickable: true }}
            breakpoints={{
              0: {
                slidesPerView: 1
              }
            }}
            className="mt-8 w-full lg:w-2/3"
          >
            {fields?.gallery?.map((image, index) => {
              return (
                <Slide key={index} className="!h-auto">
                  <LossePlaatjie src={image} className="w-full h-full max-h-[500px]" maxwidth={1999} />
                </Slide>
              )
            })}
          </Slider>
        </div>
        <div className="relative w-full mt-8">
          <div className="swiper-pagination w-[10px] h-[10px] rounded-full" />
        </div>
      </div>
    </section>
  )
}
