export default function ArrowRounded({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="none" viewBox="0 0 29 29" className={className}>
      <path
        fill="#fff"
        d="M14.5 0C6.492 0 0 6.492 0 14.5S6.492 29 14.5 29 29 22.508 29 14.5 22.508 0 14.5 0zm0 26.583c-6.673 0-12.083-5.41-12.083-12.083S7.827 2.417 14.5 2.417 26.583 7.827 26.583 14.5 21.173 26.583 14.5 26.583z"
      ></path>
      <path
        fill="#fff"
        d="M12.938 7.604a1.208 1.208 0 00-1.71 1.709l5.188 5.187-5.187 5.187a1.208 1.208 0 101.709 1.71l6.041-6.043a1.208 1.208 0 000-1.708l-6.041-6.042z"
      ></path>
    </svg>
  )
}
