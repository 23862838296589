import React from 'react'
import { LosseLayout, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'

export default function Layout({ children }: React.PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Content>{children}</Content>
    </LosseLayout>
  )
}

function Content({ children }: React.PropsWithChildren<{}>) {
  const { isScrollable } = useLosseLayout()

  return (
    <div className={`${isScrollable ? `h-full` : `h-screen overflow-hidden`}`}>
      <Header />
      <main className="min-h-screen pt-[115px] lg:pt-[139px] overflow-x-hidden">{children}</main>
      <Footer />
    </div>
  )
}
