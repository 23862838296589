import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="FormContact" className="bg-black-knight text-white">
      <div className="container">
        <div className="grid grid-cols-2 gap-x-12">
          <div className="col-span-2 sm:col-span-1 sm:-mr-14 lg:-mr-28 sm:order-last">
            <LossePlaatjie src={fields.image} />
          </div>
          <div className="col-span-2 sm:col-span-1 self-center sm:order-first">
            <Content className="content-lg mb-12 mt-12 sm:pt-0">{fields?.description}</Content>
            {fields.data?.contactdata?.map((edge, index) => (
              <LosseLink className="flex items-center mb-7 group" key={index} to={edge?.link?.url}>
                <LossePlaatjie src={edge?.icon} className="w-[38px] h-[38px] mr-5 lg:mr-16 lg:ml-16" />
                <div>
                  <Content>{edge?.description}</Content>
                  <span className="font-bold group-hover:underline">{edge?.link?.title}</span>
                </div>
              </LosseLink>
            ))}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-12">
          <div className="col-span-2 lg:col-span-1 lg:justify-self-center mb-6 lg:mb-0 mt-12 lg:mt-0 lg:order-last">
            <div className="relative lg:-top-40 bg-white py-6 lg:py-14 px-4 lg:px-16">
              <Form
                data={fields.form}
                renderLoading={() => <Loading />}
                renderStatus={({ status, content }) => (
                  <div>
                    <Content className="text-black">{content}</Content>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 lg:order-first">
            <Content className="mb-9">{fields.data?.address}</Content>
            {fields.data?.certificates?.map((certificate, index) => (
              <div className="mb-8" key={index}>
                <LosseLink to={certificate?.link?.url} className="hover:opacity-75 transition-opacity">
                  <LossePlaatjie src={certificate?.image} />
                  <span className="hidden">{certificate?.link?.title}</span>
                </LosseLink>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
