/* eslint-disable react/no-array-index-key */
import { motion } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Menu_Dropdown } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Arrow from '~/components/svg/Arrow'
import { useDetectClickOutside } from 'react-detect-click-outside'

interface HeaderSubMenuProps {
  fields: Maybe<Component_Header_MenuHeader_Menu_Dropdown>
  closeSubmenu: (e: Event) => void
}

const HeaderSubMenu: React.FC<HeaderSubMenuProps> = function ({ fields, closeSubmenu }) {
  const submenuRef = useDetectClickOutside({ onTriggered: closeSubmenu })

  return (
    <motion.div
      className="overflow-hidden w-full mb-4 lg:mb-0 lg:grid lg:grid-cols-3 lg:min-w-[980px] lg:w-fit absolute z-[999] lg:shadow-lg"
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      ref={submenuRef}
    >
      <div className="w-full lg:col-span-2 bg-white text-black lg:w-full h-full rounded-br-lg rounded-bl-lg lg:rounded-bl-none lg:rounded-br-none">
        <div
          className={`relative overflow-y-scroll h-[220px] lg:flex lg:flex-col flex-wrap justify-start py-3 px-6 lg:pt-12 lg:py-12 lg:h-[430px] lg:overflow-y-hidden`}
        >
          {fields?.submenu?.map((edge, index) => (
            <div
              className={`lg:flex lg:flex-col lg:flex-wrap mb-4 ${index === 0 || index === 1 ? 'mr-2' : 'mr-0'}`}
              key={`column-${index}`}
            >
              <LosseLink to={edge?.title?.url} className="hover:underline mb-2">
                <span className="font-bold text-xl lg:text-2xl">{edge?.title?.title}</span>
              </LosseLink>
              <div className="flex flex-col">
                {edge?.menu?.map((edge, index) => (
                  <LosseLink to={edge?.link?.url} key={`item-${index}`} className="py-1 hover:underline ">
                    {edge?.link?.title}
                  </LosseLink>
                ))}
              </div>
            </div>
          ))}
          <LosseLink to={fields?.link?.url} className="lg:absolute lg:bottom-2 lg:right-8 flex justify-end hover:underline mb-4 pb-1">
            <span className="flex items-center justfiy-end">
              <span className="font-bold">{fields?.link?.title}</span>
              <Arrow type="flush-orange" className="ml-3" />
            </span>
          </LosseLink>
        </div>
      </div>
      <div className="hidden col-span-3 lg:col-span-1 lg:flex lg:flex-col lg:justify-between bg-flush-orange p-6 lg:py-12 lg:px-8 text-white">
        <span className="lg:text-xl font-bold">{fields?.sidebar?.title}</span>
        <Content className="text-sm lg:text-base">{fields?.sidebar?.description}</Content>
        <LosseLink className="btn btn-light uppercase mt-2 lg:mt-0" to={fields?.sidebar?.cta?.link?.url}>
          <span className="flex items-center">
            <LossePlaatjie src={fields?.sidebar?.cta?.icon} className="mb-1 mr-4" maxwidth={1999} />
            <span>{fields?.sidebar?.cta?.link?.title}</span>
          </span>
        </LosseLink>
      </div>
    </motion.div>
  )
}

export default HeaderSubMenu
