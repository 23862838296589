/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import Chevron from '~/components/svg/Chevron'
import Content from '~/components/elements/Content'
import useMediaQuery from '~/hooks/useMediaQuery'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [open, setOpen] = useState(-1)

  function handleOutsideClick() {
    setOpen(-1)
  }

  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <>
      {header?.menuHeader?.menu?.map((l, index: number) => (
        <motion.li
          onHoverStart={() => isDesktop && setOpen(index)}
          onHoverEnd={() => isDesktop && setOpen(-1)}
          className="relative mr-6 2xl:ml-2 lg:mr-0"
          key={index}
        >
          <div
            className="flex flex-wrap items-center hover:bg-oil-rush"
            onClick={() => (!isDesktop && open === index ? setOpen(-1) : setOpen(index))}
          >
            {(isDesktop || !l?.dropdown?.submenu) && (
              <LosseLink
                className="text-base tracking-wider lg:text-xs xl:text-[0.825rem] 2xl:text-sm uppercase border-b-[3px] border-transparent aria-current-page:text-flush-orange lg:aria-current-page:border-white py-5 lg:py-9 px-3 transition-colors"
                to={l?.link?.url || '/'}
              >
                <Content>{l?.link?.title}</Content>
              </LosseLink>
            )}

            {!isDesktop && l?.dropdown?.submenu && (
              <span className="inline-block text-base tracking-wider lg:text-xs xl:text-[0.825rem] 2xl:text-sm uppercase border-b-[3px] border-transparent aria-current-page:text-flush-orange lg:aria-current-page:border-white py-5 lg:py-9 px-3 transition-colors">
                <Content>{l?.link?.title}</Content>
              </span>
            )}

            {l?.dropdown?.submenucheck && l?.dropdown?.submenu && (
              <div onClick={isDesktop && open === index ? () => setOpen(-1) : () => setOpen(index)} className="pr-3 border-transparent">
                <Chevron className="h-full" />
              </div>
            )}
          </div>
          {l?.dropdown?.submenucheck && l?.dropdown?.submenu && open === index && (
            <AnimatePresence>
              <HeaderSubMenu fields={l.dropdown} closeSubmenu={handleOutsideClick} />
            </AnimatePresence>
          )}
        </motion.li>
      ))}

      <li className="relative lg:absolute top-0 lg:top-[4.3rem] xl:relative xl:top-0 mr-6 ml-5 lg:mr-0">
        <LosseLink className="btn btn-flush-orange xl:btn-light mt-9 lg:mt-0" to={header.menuHeader?.cta?.link?.url}>
          <span className="flex items-center">
            <LossePlaatjie src={header.menuHeader?.cta?.icon} className="mb-1 mr-4" maxwidth={1999} />
            <span className="text-sm lg:text-base">{header.menuHeader?.cta?.link?.title}</span>
          </span>
        </LosseLink>
      </li>
      <li className="fixed bottom-4 right-4 w-[50px] h-[50px] lg:w-[81px] lg:h-[81px] transition-transform hover:scale-95">
        <LosseLink to={header.whatsappHeader?.link?.url} target={header.whatsappHeader?.link?.target || '_blank'}>
          <LossePlaatjie src={header.whatsappHeader?.icon} maxwidth={1999} />
          <span className="hidden">{header.whatsappHeader?.link?.title}</span>
        </LosseLink>
      </li>
    </>
  )
}
