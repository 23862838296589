export default function MagnifyingGlass({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26" className={className}>
      <g clipPath="url(#clip0_205_95)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M25 22.86l-7.67-7.71a9.49 9.49 0 10-2.25 2.27l7.66 7.7a1.57 1.57 0 002.26 0 1.61 1.61 0 000-2.26zM14.74 13.3l-.63.88-.88.63A6.24 6.24 0 019.55 16a6.4 6.4 0 115.19-2.7z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_205_95">
          <path fill="#fff" d="M0 0H25.45V25.6H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
