export default function Coffeebean({ className = '', type }: { className?: string; type?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22" className={className}>
      <g clipPath="url(#clip0_341_3045)">
        <path
          fill={`${type === 'active' ? '#000' : '#FFF'}`}
          d="M20.184 1.849c-3.37-3.37-10.197-2.004-15.252 3.05-5.055 5.055-6.42 11.882-3.05 15.253 3.369 3.369 10.197 2.004 15.252-3.05 5.054-5.055 6.42-11.884 3.05-15.253zM2.046 13.517c.505-2.53 1.99-5.13 4.183-7.32 2.73-2.733 6.112-4.364 9.044-4.364 1.01 0 2.414.219 3.484 1.198a17.876 17.876 0 01-8.623 7.566 17.883 17.883 0 00-7.98 6.545c-.338-1.024-.382-2.257-.108-3.625zm13.792 2.289c-2.73 2.73-6.112 4.36-9.044 4.36-1.043 0-2.509-.226-3.59-1.288a15.984 15.984 0 017.632-6.586 19.609 19.609 0 009.03-7.558c1.06 2.949-.495 7.537-4.028 11.072z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_341_3045">
          <path fill="#fff" d="M0 0H22V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
