import Content from '~/components/elements/Content'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink, LossePlaatjie, useLoaderData, useNavigate } from '@ubo/losse-sjedel'
import type { Coffeemachine, Page, Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Arrow from '~/components/svg/Arrow'
import Download from '~/components/svg/Download'
import { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import useMediaQuery from '~/hooks/useMediaQuery'

export default function BannerDetail({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page: post } = useLoaderData<LoaderData<Coffeemachine>>()
  const { page } = useLoaderData<LoaderData<Page>>()

  const left = fields.imagealignment === 'left'

  const [downloadFormOpen, setDownloadFormOpen] = useState(false)

  const [formStatus, setFormStatus] = useState('')
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [body, setBody] = useState<HTMLBodyElement | null>(null)
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const customStyle = {
    content: {
      top: '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFF',
      borderRadius: '1rem',
      maxHeight: '95vh',
      width: isDesktop ? '60vw' : '97.5vw',
      maxWidth: 900,
      minHeight: isDesktop ? '20vh' : '40vh'
    },
    overlay: {
      zIndex: 999,
      backgroundColor: 'rgba(103, 103, 103, 0.9)'
    }
  }

  useEffect(() => {
    setBody(document.querySelector('body'))
  }, [])

  if (!body) return null

  return (
    <>
      <section id="BannerDetail" className="bg-black-knight text-white">
        <div className="container py-4 sm:py-16">
          <div className={`grid grid-cols-2 sm:gap-12 ${left ? 'gap-x-5' : ''}`}>
            <div
              className={`col-span-2 justify-self-center sm:col-span-1 mb-8 sm:mb-0 ${left ? 'order-first' : 'sm:order-last sm:justify-self-end'
                }`}
            >
              <div className="relative">
                <div className="absolute -top-1/4 -left-1/4 w-[150%] h-[150%] rounded-full bg-gradient-radial from-[#363B45] via-transparent" />
                <LossePlaatjie
                  loading="eager"
                  maxwidth={750}
                  src={fields.image}
                  className="relative max-w-[300px] md:max-w-none max-h-[800px] w-auto"
                />
              </div>
            </div>
            <div className="col-span-2 sm:col-span-1 sm:order-first self-center lg:pl-20 sm:pr-1">
              <h1 className="text-4xl uppercase font-bold mb-8">{post.title}</h1>
              <Content className="content-checkmark">{fields.description}</Content>
              <LosseLink to={fields.link?.url} className="btn btn-flush-orange mt-6 mb-5">
                <span className="flex items-center">
                  <span>{fields.link?.title}</span>
                  <Arrow className="ml-11" />
                </span>
              </LosseLink>
              {fields?.secondLink && (
                <LosseLink to={fields.secondLink?.url} className="btn btn-light mb-5">
                  <span className="flex items-center">
                    <span>{fields.secondLink?.title}</span>
                    <Arrow className="ml-11" />
                  </span>
                </LosseLink>
              )}
              {fields.download && (
                <button className="underline" onClick={() => setDownloadFormOpen(true)}>
                  <span className="flex items-center mx-14">
                    <Download className="mr-3" />
                    {fields.download.description ? <Content>{fields.download?.description}</Content> : <span>Download brochure</span>}
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
      <ReactModal
        ariaHideApp
        appElement={body}
        style={customStyle}
        isOpen={downloadFormOpen}
        onRequestClose={() => setDownloadFormOpen(false)}
      >
        <div className="p-6 lg:py-12 lg:px-24">
          {formStatus !== 'done' && (
            <h2 className="text-2xl text-center font-bold mb-7">Vul onderstaande velden in en ontvang deze gratis brochure</h2>
          )}
          <Form
            defaultFields={{
              input_1: page.title ?? ''
            }}
            onSubmitDone={() => {
              setDownloadLoading(true)
              setTimeout(() => {
                window.open(fields.download?.mediaItemUrl || '#', '_blank', 'rel=noreferrer')
              }, 2000)
            }}
            className="form-download"
            generate
            data={fields.form}
            renderLoading={() => <Loading />}
            renderStatus={({ status, content }) => {
              setFormStatus(status)
              return (
                <div className="flex flex-col">
                  <Content className="children-h2:text-2xl children-h2:font-bold hover:children-a:underline">{content}</Content>
                  <button className="underline" onClick={() => setDownloadFormOpen(false)}>
                    Scherm sluiten
                  </button>
                </div>
              )
            }}
          />
        </div>
      </ReactModal>
    </>
  )
}
