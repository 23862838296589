import { LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { LosseLink, type LoaderData } from '@ubo/losse-sjedel'
import type { Component_Footer } from '~/graphql/types'
import Content from '../elements/Content'
import Email from '../svg/Email'
import Logo from '../svg/Logo'
import Phone from '../svg/Phone'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export default function Footer() {
  const { footer } = useLoaderData<FooterLoaderData>()

  return (
    <footer>
      <div className="text-white bg-oil-rush">
        <div className="container grid grid-cols-9 sm:gap-x-20 py-10">
          <div className="col-span-9 md:col-span-4 lg:col-span-3">
            <LosseLink aria-label="Home" to="/">
              <Logo />
            </LosseLink>
            <Content className="my-8">{footer.contactFooter?.address}</Content>
            <LosseLink
              to={footer.contactFooter?.phone?.url}
              target={footer.contactFooter?.phone?.target || '_blank'}
              className="flex items-center hover:underline"
            >
              <Phone />
              <span className="font-bold ml-3">{footer.contactFooter?.phone?.title}</span>
            </LosseLink>
            <LosseLink
              to={footer.contactFooter?.email?.url}
              target={footer.contactFooter?.email?.target || '_blank'}
              className="flex items-center hover:underline"
            >
              <Email />
              <span className="font-bold ml-3">{footer.contactFooter?.email?.title}</span>
            </LosseLink>
          </div>
          <div className="col-span-9 md:col-span-5 lg:col-span-2 mt-10">
            <Content>{footer.buttonsFooter?.description}</Content>
            <ul>
              {footer.buttonsFooter?.buttons?.map((button, index) => (
                <li key={index} className="mt-6">
                  <LosseLink to={button?.link?.url} className="btn btn-light py-2 lg:w-full text-center">
                    {button?.link?.title}
                  </LosseLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-9 md:col-span-4 lg:col-span-2 mt-10">
            <Content>{footer.menuFooter?.description}</Content>
            <ul className="mt-6">
              {footer.menuFooter?.menu?.map((edge, index) => (
                <li className="py-1" key={index}>
                  <LosseLink className="hover:underline" to={edge?.link?.url || '/'}>
                    <Content>{edge?.link?.title}</Content>
                  </LosseLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-9 md:col-span-5 lg:col-span-2 mt-10">
            <Content>{footer.socialsFooter?.description}</Content>
            <ul className="flex mt-5">
              {footer.socialsFooter?.socials?.map((social, index) => (
                <LosseLink key={index} to={social?.link?.url} target={social?.link?.target || '_blank'} className="mr-3">
                  <LossePlaatjie src={social?.icon} />
                  <span className="hidden">{social?.link?.title}</span>
                </LosseLink>
              ))}
            </ul>
            <div className="mt-8">
              {footer.socialsFooter?.certifications?.map((certification, index) => (
                <LosseLink key={index} to={certification?.link?.url}>
                  <LossePlaatjie src={certification?.image} />
                  <span className="hidden">{certification?.link?.title}</span>
                </LosseLink>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white text-hard-coal py-4">
        <div className="container-xl flex flex-wrap justify-between items-center">
          <div className="flex items-center gap-4 mb-4 lg:mb-0">
            {footer?.keurmerk?.map((keurmerk: any, index: any) => (
              <LossePlaatjie src={keurmerk?.image} key={index} className="w-[40px] h-[40px] object-cover" />
            ))}
          </div>
          <div className="flex flex-wrap items-center">
            {footer.bottomFooter?.map((edge: any, index: any) => (
              <LosseLink
                key={index}
                to={edge?.link?.url}
                target={edge?.link?.target || '_self'}
                className="text-sm md:text-base mr-7 lg:ml-7 lg:mr-0 hover:underline"
              >
                {edge?.link?.title}
              </LosseLink>
            ))}
            <div
              // @ts-ignore
              onClick={() => Cookiebot.show()}
            >
              <p className="text-sm md:text-base mr-7 lg:ml-7 lg:mr-0 hover:underline cursor-pointer">Cookies</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
