import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { ReactElement } from 'react'
import Arrow from '~/components/svg/Arrow'

export default function Highlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const __typename = fields.highlighted![0]?.__typename

  let component: ReactElement
  switch (__typename?.toLowerCase()) {
    case 'coffeemachine':
      component = <Coffeemachines fields={fields} />
      break
    default:
      component = <Coffee fields={fields} />
      break
  }

  return <section id={`Highlighted${__typename}`}>{component}</section>
}

function Coffeemachines({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <div className="container pt-10 pb-8 sm:pt-24 sm:pb-16">
      <Content className="children-h2:text-3xl sm:children-h2:text-5xl lg:children-h2:text-7xl opacity-30">{fields.description}</Content>
      <div className="grid md:grid-cols-6 sm:gap-x-20 justify-self-center lg:-mt-10 md:mx-auto lg:w-[81%]">
        {fields.highlighted?.map((coffeemachine, index) => (
          <div className="col-span-3 md:col-span-2" key={index}>
            <LosseLink to={coffeemachine?.uri} className="relative z-10 group">
              <LossePlaatjie
                maxwidth={1000}
                src={coffeemachine?.recap?.thumbnailHighlighted}
                className="w-auto md:w-[240px] max-w-full h-full max-h-56 md:max-h-72 lg:max-h-96 object-cover mx-auto md:max-w-[240px] group-hover:scale-95 transition-transform"
              />
              <div className="flex flex-col items-center mt-3">
                <h3 className="text-base sm:text-xl mb-3 font-bold text-center">{coffeemachine?.title}</h3>
                <ul className="flex flex-col ">
                  {coffeemachine?.recap?.usps?.map((usp, index) => (
                    <li key={index} className="flex items-center">
                      <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.1454 0L5.01224 6.95307L1.99556 3.57052L0 5.5353L4.8736 11L14 2.11224L12.1454 0Z" fill="#FF6C01" />
                      </svg>
                      <span className="ml-2">{usp?.item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <p className="flex items-center justify-center text-center mt-4">
                <span className="mr-3">Lees meer</span>
                <Arrow type="flush-orange" />
              </p>
            </LosseLink>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-14">
        <LosseLink to={fields.cta?.link?.url} className="btn btn-black-knight">
          {fields.cta?.link?.title}
        </LosseLink>
      </div>
    </div>
  )
}

function Coffee({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  return (
    <div className="container grid grid-cols-3">
      {fields.highlighted?.map((coffeemachine, index) => (
        <div className="col-span-1" key={index}>
          <LossePlaatjie loading="eager" src={coffeemachine?.recap?.thumbnail} maxwidth={1000} />
          <h3>{coffeemachine?.title}</h3>
          <ul className="flex flex-col ">
            {coffeemachine?.recap?.usps?.map((usp, index) => (
              <li key={index} className="flex items-center">
                <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.1454 0L5.01224 6.95307L1.99556 3.57052L0 5.5353L4.8736 11L14 2.11224L12.1454 0Z" fill="#FF6C01" />
                </svg>
                <span className="ml-2">{usp?.item}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
