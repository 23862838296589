import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import Arrow from '~/components/svg/Arrow'
import type { Page_Flexcontent_Flex_Services } from '~/graphql/types'

export default function ServicesGrid({ fields }: { fields: Page_Flexcontent_Flex_Services }) {
  return (
    <section id="ServicesGrid" className="relative text-white pt-20 pb-32 sm:pt-60 lg:pt-40 sm:pb-60">
      <div className="absolute w-full h-1/2 sm:h-2/5 bg-black-knight top-0" />
      <div className="absolute w-full h-1/2 sm:h-3/5 bg-white bottom-0" />
      <div className="sm:hidden">
        <Swipe fields={fields} />
      </div>
      <div className="hidden sm:block relative mx-auto max-w-[1500px]">
        <Grid fields={fields} />
      </div>
      <div className="absolute flex justify-center bottom-4 lg:bottom-24 xl:bottom-32 w-full">
        <div className="container">
          <div className="grid grid-cols-3 items-center gap-10 [&_img]:grayscale sm:w-1/2 ">
            <img src="img/animo.png" alt="" />
            <img src="img/bravilor.png" alt="" />
            <img src="img/jura.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

function Swipe({ fields }: { fields: Page_Flexcontent_Flex_Services }) {
  return (
    <EndlessContainer>
      <div className="relative">
        <Content className="content-lg pt-10 pb-4">{fields?.description}</Content>
        <LossePlaatjie src={fields.image} className="absolute w-full top-0 -right-40" maxwidth={1999} />
      </div>
      <Slider slidesPerView={1.3} spaceBetween={20}>
        {fields.services?.map((service, index) => (
          <Slide key={index} className="!h-auto">
            <div className="flex flex-col h-full">
              <LossePlaatjie src={service?.image} maxwidth={1999} />
              <div className="bg-oil-rush p-4 flex flex-col h-full">
                <Content className="content-md mb-4">{service?.description}</Content>
                <div className="mt-auto">
                  <LosseLink to={service?.link?.url} className="flex items-center hover:underline">
                    <span>{service?.link?.title}</span>
                    <Arrow className="ml-4" />
                  </LosseLink>
                </div>
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
    </EndlessContainer>
  )
}

function Grid({ fields }: { fields: Page_Flexcontent_Flex_Services }) {
  return (
    <>
      <LossePlaatjie src={fields.image} className="absolute -top-20 sm:-top-72 -left-48 sm:-left-40" />
      <div className="container grid grid-cols-2 lg:pl-12">
        <div className="col-span-2 sm:col-span-1">
          <div className="flex justify-end sm:justify-center">
            <Content className="relative content-lg pt-10 pb-4 sm:pb-14">{fields?.description}</Content>
          </div>
          <div className="relative mb-6 sm:mb-0 ">
            <LossePlaatjie src={fields.services![0]?.image} className="w-full" maxwidth={1999} />
            <LosseLink
              to={fields.services![0]?.link?.url}
              className="group sm:absolute hover:scale-105 transition-transform bg-oil-rush px-4 py-3 lg:px-10 md:py-9 sm:-bottom-52 lg:-bottom-32 right-0 sm:w-2/3 lg:w-1/2"
            >
              <Content className="content-md">{fields.services![0]?.description}</Content>
              <div className="mt-4 lg:mt-14 flex items-center">
                <span className="group-hover:underline">{fields.services![0]?.link?.title}</span>
                <Arrow className="ml-4" />
              </div>
            </LosseLink>
          </div>
        </div>
        <div className="col-span-2 sm:col-span-1">
          <div className="relative mb-6 sm:mb-0">
            <LossePlaatjie src={fields.services![1]?.image} className="w-full" maxwidth={1999} />
            <LosseLink
              to={fields.services![1]?.link?.url}
              className="group sm:absolute hover:scale-105 transition-transform bg-oil-rush px-4 py-3 lg:px-10 md:py-9 top-0 sm:-top-52 lg:-top-[3.7rem] left-0 sm:w-2/3 lg:w-1/2"
            >
              <Content className="content-md">{fields.services![1]?.description}</Content>
              <div className="mt-4 lg:mt-14 flex items-center">
                <span className="group-hover:underline">{fields.services![1]?.link?.title}</span>
                <Arrow className="ml-4" />
              </div>
            </LosseLink>
          </div>
          <div className="relative mb-6 sm:mb-0">
            <LossePlaatjie src={fields.services![2]?.image} className="w-full" maxwidth={1999} />
            <LosseLink
              to={fields.services![2]?.link?.url}
              className="group sm:absolute hover:scale-105 transition-transform bg-oil-rush px-4 py-3 lg:px-10 md:py-9 sm:-bottom-52 lg:-bottom-32 right-0 sm:w-2/3 lg:w-1/2"
            >
              <Content className="content-md">{fields.services![2]?.description}</Content>
              <div className="mt-4 lg:mt-14 flex items-center">
                <span className="group-hover:underline">{fields.services![2]?.link?.title}</span>
                <Arrow className="ml-4" />
              </div>
            </LosseLink>
          </div>
        </div>
      </div>
    </>
  )
}
