import { useLosseBlogBink } from '@ubo/losse-sjedel'
import ChevronDark from '../svg/ChevronDark'

export default function Pagination() {
  const { hasPrevPage, hasNextPage, prevPage, nextPage } = useLosseBlogBink()

  return (
    <div className="mt-10 flex justify-end">
      <button type="button" disabled={!hasPrevPage} className="disabled:opacity-20 mr-3 hover:opacity-70" onClick={prevPage}>
        <ChevronDark className="w-[20px] h-[20px]" />
      </button>
      <button type="button" disabled={!hasNextPage} className="disabled:opacity-20 ml-3 hover:opacity-70" onClick={nextPage}>
        <ChevronDark className="w-[20px] h-[20px] rotate-180" />
      </button>
    </div>
  )
}
