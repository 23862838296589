export default function Phone({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" className={className}>
      <g fill="#fff" clipPath="url(#clip0_205_181)">
        <path d="M15.815 12.39c-.41-.427-.905-.655-1.427-.655s-1.019.224-1.446.65l-1.335 1.33c-.11-.06-.22-.114-.326-.169a5.407 5.407 0 01-.418-.224c-1.252-.791-2.389-1.827-3.48-3.165-.528-.667-.883-1.229-1.14-1.799.346-.316.667-.646.98-.962l.355-.359c.888-.886.888-2.035 0-2.921L6.422 2.96c-.131-.13-.264-.263-.393-.4-.254-.264-.52-.532-.793-.786-.41-.405-.898-.62-1.416-.62-.518 0-1.015.215-1.437.62l-.009.009L.937 3.232A3.086 3.086 0 00.02 5.194c-.102 1.233.264 2.381.54 3.133.688 1.846 1.708 3.554 3.235 5.386a19.867 19.867 0 006.624 5.18c.972.46 2.272 1.002 3.72 1.097.088.004.181.008.264.008.976 0 1.797-.35 2.44-1.047.003-.008.012-.012.016-.02.232-.269.48-.524.74-.765.182-.173.37-.354.55-.544.418-.435.638-.942.638-1.46 0-.52-.224-1.027-.651-1.449l-2.321-2.323zm1.513 4.444c-.004 0-.004.005 0 0-.165.178-.334.338-.515.515-.287.267-.56.549-.816.845-.427.456-.93.67-1.59.67-.063 0-.13 0-.194-.003-1.256-.08-2.422-.57-3.298-.988a18.775 18.775 0 01-6.24-4.88c-1.44-1.735-2.404-3.339-3.043-5.061-.393-1.051-.537-1.87-.475-2.643.033-.475.24-.92.58-1.253l1.442-1.44c.208-.194.427-.3.643-.3.264 0 .482.159.617.296l.013.013c.258.24.503.49.76.755l.402.41 1.154 1.152c.449.448.449.861 0 1.309-.122.122-.24.245-.363.363-.355.363-.693.7-1.061 1.03-.009.008-.017.012-.021.02-.364.364-.296.718-.22.96l.013.037c.3.726.722 1.41 1.365 2.225l.004.004c1.167 1.435 2.397 2.554 3.752 3.41.168.104.342.198.52.283.152.076.296.148.418.224.017.009.034.021.053.03.13.068.273.104.419.105.35 0 .57-.22.642-.291l1.45-1.444c.143-.143.37-.316.638-.316s.476.164.609.308l2.337 2.334c.436.43.436.874.005 1.32v.001zM10.81 4.756a5.445 5.445 0 014.43 4.423.565.565 0 00.66.467.572.572 0 00.469-.659 6.566 6.566 0 00-1.83-3.524 6.582 6.582 0 00-3.53-1.828.574.574 0 00-.656.686.56.56 0 00.457.435zM19.992 8.823a10.812 10.812 0 00-3.014-5.803A10.838 10.838 0 0011.167.011a.57.57 0 00-.394 1.045c.063.04.134.066.208.078a9.68 9.68 0 015.194 2.688 9.657 9.657 0 012.692 5.187.566.566 0 00.66.466.56.56 0 00.465-.65v-.002z"></path>
      </g>
      <defs>
        <clipPath id="clip0_205_181">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
