// Sjedel
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'

// Types
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

// Components
import Content from '~/components/elements/Content'
import Arrow from '~/components/svg/Arrow'

export default function BannerLanding({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerLanding" className="bg-black-knight text-white">
      <div className="container-xl py-4 sm:py-16">
        <div className="grid grid-cols-2">
          <div className="col-span-2 sm:col-span-1 sm:order-last mb-8 sm:mb-0">
            <LossePlaatjie loading="eager" src={fields.image} maxwidth={1999} />
          </div>
          <div className="col-span-2 sm:col-span-1 sm:order-first self-center xl:pl-20 sm:pr-10">
            <Content className="content-xxl">{fields.description}</Content>
            <LosseLink to={fields.link?.url} className="btn btn-flush-orange mt-6">
              <span className="flex items-center">
                <span>{fields.link?.title}</span>
                <Arrow className="ml-11" />
              </span>
            </LosseLink>
          </div>
        </div>
      </div>
    </section>
  )
}
