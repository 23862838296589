import { LosseFlex } from '@ubo/losse-sjedel'
import type { Page_Flexcontent } from '~/graphql/types'

// Flex elements
import BannerShell from './Banner/BannerShell'
import TextShell from './Text/TextShell'
import Fragment from './Fragment'
import FormShell from './Form/FormShell'
import PostsShell from './Posts/PostsShell'
import ServicesShell from './Services/ServicesShell'
import HeroShell from './Hero/HeroShell'
import SliderShell from './Slider/SliderShell'
import SearchShell from './Search/SearchShell'

interface FlexContentHandlerProps {
  flex: Page_Flexcontent['flex']
  prefix: string
}

export default function FlexContentHandler({ flex, prefix = 'page_Flexcontent' }: FlexContentHandlerProps) {
  const flexers = {
    [`${prefix}_Flex_Banner`]: BannerShell,
    [`${prefix}_Flex_Text`]: TextShell,
    [`${prefix}_Flex_Services`]: ServicesShell,
    [`${prefix}_Flex_Hero`]: HeroShell,
    [`${prefix}_Flex_Slider`]: SliderShell,
    [`${prefix}_Flex_Search`]: SearchShell,
    [`${prefix}_Flex_Posts`]: PostsShell,
    [`${prefix}_Flex_Form`]: FormShell,
    [`${prefix}_Flex_Fragment`]: Fragment
  }

  // @ts-ignore
  return <LosseFlex flex={flex} flexers={flexers} />
}
