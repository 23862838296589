import { useEffect } from 'react'
import { type LoaderData, useLoaderData, pageLoader as loader, useLocation } from '@ubo/losse-sjedel'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'

export { loader }

export default function $page() {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      document.getElementById(hash.replace('/#S+/g', ''))?.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }, [hash])

  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex} />
    </Layout>
  )
}
