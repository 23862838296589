import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Arrow from '~/components/svg/Arrow'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextImage({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const side = fields.imageposition === 'side'
  const left = fields.imagealignment === 'left'

  let buttonType: string
  let buttonArrow: boolean
  switch (fields.btncolor) {
    case 'flushorange':
      buttonType = 'btn-flush-orange px-8'
      buttonArrow = false
      break
    default:
      buttonType = 'btn-black-knight'
      buttonArrow = true
      break
  }

  let bgColor: string
  let textColor: string
  let gradient: string
  switch (fields.bgcolor) {
    case 'white':
      bgColor = 'bg-white'
      textColor = 'text-black'
      gradient = ''
      break
    default:
      bgColor = 'bg-black-knight'
      textColor = 'text-white'
      gradient = `bg-gradient-to-r  ${left ? 'from-transparent via-transparent to-black-knight' : 'from-black-knight to-transparent'}`
      break
  }

  return (
    <section id="TextImage" className={`${bgColor} ${textColor} py-8 sm:py-24`}>
      <div className="container grid grid-cols-2 gap-2 md:gap-4 lg:gap-8">
        <div className="relative z-10 col-span-2 sm:col-span-1 self-center">
          <div className="py-10 lg:py-0">
            <Content className="content-lg mt-5 sm:mt-0">{fields?.description}</Content>
            {fields.link?.title && (
              <LosseLink to={fields.link?.url} className={`btn ${buttonType} mt-9`}>
                <span className="flex items-center">
                  <span>{fields.link?.title}</span>
                  {buttonArrow && <Arrow className="ml-7" type="dark" />}
                </span>
              </LosseLink>
            )}
          </div>
        </div>
        <div className={`relative col-span-2 sm:col-span-1 ${left ? 'order-first' : 'order-last'}`}>
          <LossePlaatjie
            src={fields.image}
            maxwidth={1999}
            className={`relative max-w-[300px] mx-auto sm:max-w-none w-full h-full object-cover ${side ? (left ? 'sm:-ml-24 sm:w-[115%]' : 'sm:-mr-24 sm:w-[120%]') : 'sm:max-lg:-ml-8'
              }`}
          />
          <div className={`hidden sm:block absolute top-0 ${left ? 'left-7' : 'left-0'} w-full h-full ${gradient}`} />
        </div>
      </div>
    </section>
  )
}
