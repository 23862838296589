import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextSpecs({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextSpecs" className="bg-school-ink text-white py-14">
      <div className="container py-5">
        <Content className="content-xl mb-16">{fields?.title}</Content>
        <div className="grid grid-cols-2 gap-x-20">
          <div className="col-span-2 lg:col-span-1">
            <Content className="content-checkmark">{fields.description}</Content>
          </div>
          <div className="col-span-2 lg:col-span-1 mt-6 sm:mt-0">
            <Content>{fields.specs}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
