export default function Chevron({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" fill="none" viewBox="0 0 8 5" className={className}>
      <path
        fill="#fff"
        d="M.555.008a.582.582 0 00-.304.095.558.558 0 00-.204.238.539.539 0 00.096.587l3.418 3.878A.569.569 0 003.995 5a.585.585 0 00.434-.194L7.85.928A.55.55 0 007.999.52.54.54 0 007.8.133a.575.575 0 00-.425-.13.583.583 0 00-.391.206l-2.99 3.389L1.006.209A.569.569 0 00.555.008z"
      ></path>
    </svg>
  )
}
