export default function CoffeebeanActive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <g fill="#EADECC" clipPath="url(#clip0_341_3033)">
        <path d="M10.1 10.631a17.905 17.905 0 009.5-9.235C16.074-1.335 9.69.135 4.893 4.93.343 9.478-1.208 15.458.98 19.068a17.913 17.913 0 019.12-8.437z"></path>
        <path d="M20.935 2.864a19.648 19.648 0 01-10.132 9.463c-3.821 1.58-6.815 4.505-8.512 8.251 3.501 2.851 9.968 1.406 14.816-3.44 4.61-4.607 6.14-10.68 3.828-14.274z"></path>
      </g>
      <defs>
        <clipPath id="clip0_341_3033">
          <path fill="#fff" d="M0 0H22V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
