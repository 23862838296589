import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'
import CountUp from 'react-countup'
import { InView, useInView } from 'react-intersection-observer'

export default function HeroAchievements({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const { ref, inView } = useInView({
    threshold: 0.15
  })

  return (
    <section id="HeroAchievements" className="relative bg-black-knight text-white">
      <div className="relative mx-auto max-w-[1920px]">
        <LossePlaatjie src={fields.image} className="absolute w-full sm:h-full" maxwidth={1999} />
        <div className="absolute w-full h-full bg-black-knight bg-opacity-80 mix-blend-multiply" />
        <div className="relative container pt-12 pb-10 sm:pt-32 sm:pb-28">
          <Content className="content-lg">{fields.description}</Content>
          <div className="grid grid-cols-4 gap-x-6 lg:gap-x-28 mt-14">
            {fields.usps?.map((usp, index) => (
              <div className="col-span-2 sm:col-span-1 flex flex-col items-center mb-4 sm:mb-0" key={index}>
                <LossePlaatjie src={usp?.icon} className="w-[85px] h-[85px]" />
                <h3 className="font-bold text-center mt-5 lg:text-2xl">{usp?.title}</h3>
                <Content className="text-center mt-2">{usp?.description}</Content>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-3 gap-x-8 lg:gap-x-44 mt-12">
            {fields.achievements && fields.achievements.length > 0 && (
              <div className="col-span-3 sm:col-span-1 mb-12 sm:mb-0">
                <div className="flex justify-center lg:justify-start items-center">
                  <span className="text-2xl lg:text-5xl font-bold opacity-50">{fields.achievements[0]?.prefix}</span>
                  <span className="text-8xl lg:text-12xl font-bold opacity-50 tracking-wide">{fields.achievements[0]?.amount}</span>
                  <span className="text-8xl lg:text-12xl font-bold opacity-50 tracking-wide">{fields.achievements[0]?.suffix}</span>
                </div>
                <h3 className="uppercase text-center lg:text-left lg:text-2xl lg:mt-16">{fields.achievements[0]?.title}</h3>
              </div>
            )}

            <div className="col-span-3 sm:col-span-2 self-center grid grid-cols-2 sm:gap-x-20" ref={ref}>
              {fields.achievements?.map((achievement, index) => {
                if (index === 0) {
                  return null
                }
                const pfx = achievement?.prefix || ''
                const sfx = achievement?.suffix || ''
                return (
                  <CountUp start={0} end={achievement?.amount || 0} duration={2.75} separator="." prefix={pfx} suffix={sfx} key={index}>
                    {({ countUpRef, start }) => (
                      <InView onChange={() => start()} triggerOnce>
                        <div className="col-span-2 sm:col-span-1 sm:mb-20">
                          <span
                            ref={countUpRef}
                            className="w-full block text-center sm:text-left sm:flex text-flush-orange text-4xl lg:text-5xl font-bold mb-2"
                          />
                          <h3 className="text-center sm:text-left uppercase text-lg lg:text-2xl sm:pr-24">{achievement?.title}</h3>
                        </div>
                      </InView>
                    )}
                  </CountUp>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
