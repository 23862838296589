import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextDefault" className="bg-black-knight text-white py-12 sm:py-28">
      <div className="container">
        <div className="sm:w-3/4">
          <Content>{fields?.description}</Content>
        </div>
      </div>
    </section>
  )
}
