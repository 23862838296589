import React from 'react'

// Components
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'

// .button

const Content: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({ ...rest }) => {
  return (
    <LosseContent
      {...rest}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            return (
              <Button to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return null
      }}
    >
      {rest.children}
    </LosseContent>
  )
}

export default Content
