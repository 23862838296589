import Content from '~/components/elements/Content'
import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Arrow from '~/components/svg/Arrow'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerDefault" className="bg-black-knight text-white pb-12">
      <div className="container">
        <div className="grid grid-cols-2 md:gap-x-7">
          <div className="col-span-2 md:col-span-1 md:-mr-14 lg:-mr-28 md:order-last">
            <LossePlaatjie loading="eager" src={fields.image} className="w-full" maxwidth={1999} />
          </div>
          <div className="col-span-2 md:col-span-1 self-center md:order-first">
            <Content className="content-xxl mt-4 mb-2 md:mb-12 md:mt-12 md:pt-0">{fields?.description}</Content>
            {fields.link?.title && (
              <LosseLink to={fields.link.url} className="btn btn-flush-orange">
                <span className="flex items-center">
                  <span>{fields.link.title}</span>
                  <Arrow className="ml-3" />
                </span>
              </LosseLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
