import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Text } from '~/graphql/types'

export default function TextVideo({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  return (
    <section id="TextVideo" className="relative bg-black-knight text-white">
      <div className="relative max-w-[1920px] mx-auto pt-56 pb-4 sm:pb-24">
        {fields.image && <LossePlaatjie src={fields.image} className="absolute top-0 sm:-top-28 lg:-top-36 -right-40" />}
        <div className="container grid grid-cols-2 gap-x-16">
          <div className="col-span-2 md:col-span-1">
            <Content className="content-lg">{fields?.description}</Content>
            {fields.link && (
              <LosseLink to={fields.link?.url} className="btn btn-light mt-9">
                <span className="flex items-center">
                  <Content>{fields.link?.title}</Content>
                </span>
              </LosseLink>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 mt-8 md:mt-0">
            <div className="relative aspect-video">
              <iframe
                src={fields.vimeo || ''}
                title="Banner video"
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
