import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroContact({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  return (
    <section id="HeroContact" className="bg-black-knight text-white sm:pt-8 sm:pb-28">
      <div className="container">
        <div className={`grid grid-cols-3 ${fields.image ? 'sm:-mt-52 sm:mb-12' : ''}`}>
          <div className="col-span-3 sm:col-span-2 self-end">
            {fields.description && <Content className="content-xxxl opacity-20 mb-18">{fields.description}</Content>}
          </div>
          <div className="col-span-3 sm:col-span-1">
            {fields.image && <LossePlaatjie maxwidth={1999} src={fields.image} className="w-full max-w-[250px] sm:max-w-none mx-auto" />}
          </div>
        </div>
        <div className="grid grid-cols-3">
          {fields.contactdata?.map((edge, index) => (
            <div
              className={`col-span-3 md:col-span-1 border-b-2 md:border-r-2 md:border-b-0 p-16 ${
                // @ts-ignore
                index !== fields.contactdata?.length - 1 ? ' border-white' : 'border-transparent'
              }`}
              key={index}
            >
              <LosseLink to={edge?.link?.url} target={edge?.link?.target || '_self'} className="group flex flex-col items-center">
                <LossePlaatjie src={edge?.icon} className="mb-8" maxwidth={1999} />
                <span className="block text-center group-hover:underline">{edge?.title}</span>
                <span className="block text-center font-bold">{edge?.link?.title}</span>
              </LosseLink>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
