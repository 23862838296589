/* eslint-disable react-hooks/exhaustive-deps */
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import { Form } from '@ubo/losse-sjedel'
import { losseLoader, useFetcher, useLoaderData, useSearchParams } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import Loading from '~/components/elements/Loading'
import Chevron from '~/components/svg/Chevron'
import ChevronDark from '~/components/svg/ChevronDark'
import type { ContentType, Page, Page_Flexcontent_Flex_Text, Post, RootQueryToContentNodeConnection } from '~/graphql/types'

export default function SearchDefault({ fields }: { fields: Page_Flexcontent_Flex_Text }) {
  const data = useLoaderData<LoaderData<Page>>()
  const [searchParams, setSearchParams] = useSearchParams()

  const fetcher = useFetcher<{ contentNodes: RootQueryToContentNodeConnection; contentTypes: { nodes: ContentType[] } }>()

  useEffect(() => {
    if (!searchParams.has('q')) return

    const query = losseLoader({
      name: 'Search',
      variables: {
        search: searchParams.get('q') || '',
        after: searchParams.get('after') || ''
      }
    })

    fetcher.load(query)
  }, [searchParams.get('q'), searchParams.get('after')])

  function getContentTypeLabel(name: string) {
    return fetcher.data?.contentTypes.nodes.find((contentType) => contentType.name === name)
  }

  return (
    <section id="SearchDefault">
      <div className="bg-black-knight text-white py-12">
        <div className="container flex justify-center">
          <Form action={new URL(data.request.url).pathname} method="get" className="relative flex mt-14">
            <input
              type="search"
              className="bg-white text-sm xl:w-[450px] font-light placeholder:font-normal placeholder:text-opacity-50 text-black py-2 px-12 border-white border-[1px] border-solid rounded-full"
              name="q"
              placeholder="Voorbeeld: animo"
              defaultValue={searchParams.get('q') || undefined}
            />
            <button className="btn btn-flush-orange -ml-10 py-1" type="submit">
              Zoeken
            </button>
          </Form>
        </div>
      </div>
      <div className="py-10 container w-2/3">
        {(fetcher.state === 'loading' || fetcher.state === 'submitting') && (
          <div className="flex justify-center">
            <Loading />
          </div>
        )}
        {fetcher.state === 'idle' && (
          <>
            <div className="grid grid-cols-2 sm:gap-y-10 gap-x-10 gap-y-4">
              {fetcher.data?.contentNodes?.edges?.length === 0 && <div className="text-center">No results found</div>}
              {fetcher.data?.contentNodes?.edges?.map((edge) => {
                if (!edge) return null
                if (!edge.node) return null
                const node = edge.node as Post | Page

                return (
                  <LosseLink
                    to={edge.node.uri || '#'}
                    className="col-span-2 lg:col-span-1 flex flex-col sm:px-6 sm:py-6 px-4 py-4 border relative"
                    key={node.id}
                  >
                    <span className="label mr-auto mb-3">{getContentTypeLabel(node.contentTypeName)?.label}</span>
                    <strong className="uppercase sm:text-xl text-lg">{node.title}</strong>
                    <ChevronDark className="absolute top-0 right-4 bottom-0 my-auto rotate-180" />
                  </LosseLink>
                )
              })}
            </div>
            <div className="mt-4 flex justify-between">
              {fetcher.data?.contentNodes?.pageInfo?.hasPreviousPage && (
                <button
                  onClick={() => {
                    searchParams.delete('after')
                    searchParams.set('before', fetcher.data?.contentNodes.pageInfo?.endCursor || '')
                    setSearchParams(searchParams)
                    window.scrollTo(0, 0)
                  }}
                  className="flex items-center text-sm uppercase"
                  type="button"
                >
                  <Chevron className="w-4 h-4" />
                  <span className="ml-2">Previous page</span>
                </button>
              )}
              {fetcher.data?.contentNodes?.pageInfo?.hasNextPage && (
                <button
                  onClick={() => {
                    searchParams.delete('before')
                    searchParams.set('after', fetcher.data?.contentNodes.pageInfo?.endCursor || '')
                    setSearchParams(searchParams)
                    window.scrollTo(0, 0)
                  }}
                  className="flex items-center text-sm uppercase ml-auto"
                  type="button"
                >
                  <span className="mr-2">Next page</span>
                  <Chevron className="w-4 h-4 rotate-180" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  )
}
