export default function Email({ className = '' }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" fill="none" viewBox="0 0 21 16" className={className}>
      <g fill="#fff" clipPath="url(#clip0_205_185)">
        <path d="M18.436 0H2.568A2.569 2.569 0 000 2.571v10.86a2.575 2.575 0 002.568 2.571h15.864A2.568 2.568 0 0021 13.432V2.574A2.574 2.574 0 0019.419.197 2.565 2.565 0 0018.436 0zm1.395 13.431a1.4 1.4 0 01-1.395 1.397H2.568a1.396 1.396 0 01-1.395-1.397V2.575A1.399 1.399 0 012.568 1.18h15.864a1.396 1.396 0 011.393 1.396v10.856h.006z"></path>
        <path d="M13.244 7.64l5.136-4.61a.588.588 0 10-.787-.875l-7.082 6.362-1.382-1.235c-.004-.004-.009-.009-.009-.013a.869.869 0 00-.095-.083L3.402 2.151a.588.588 0 10-.782.879l5.197 4.65-5.175 4.85a.59.59 0 00.804.862l5.253-4.92 1.425 1.274a.585.585 0 00.782-.004l1.467-1.314 5.223 4.968a.588.588 0 00.808-.852l-5.16-4.903z"></path>
      </g>
      <defs>
        <clipPath id="clip0_205_185">
          <path fill="#fff" d="M0 0H21V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
