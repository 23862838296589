import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import ArrowRounded from '~/components/svg/ArrowRounded'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderDefault({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section id="SliderDefault" className="bg-black-knight text-white py-16">
      <div className="container py-5">
        <Content className="content-lg">{fields?.description}</Content>
        <div className="flex items-center">
          <div className="custom-sliderdefault-button-prev px-4 py-3 hover:scale-105 transition-transform" role="button">
            <ArrowRounded className="rotate-180" />
          </div>
          <Slider
            mousewheel={{
              forceToAxis: true
            }}
            navigation={{
              prevEl: '.custom-sliderdefault-button-prev',
              nextEl: '.custom-sliderdefault-button-next'
            }}
            autoplay={{
              delay: 5000
            }}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 5
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 10
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15
              }
            }}
            className="my-14"
          >
            {fields?.slider?.map((slide, index) => {
              return (
                <Slide key={index} className="overflow-hidden max-h-[113px] !h-auto">
                  <LosseLink to={`${slide?.link?.url}#${slide?.link?.title?.toLowerCase()}`} className="relative text-white group">
                    <LossePlaatjie
                      src={slide?.image}
                      className="w-full h-full group-hover:scale-105 transition-transform object-cover"
                      maxwidth={1999}
                    />
                    <div className="absolute top-0 w-full h-full bg-black-knight bg-opacity-40 mix-blend-multiply" />
                    <div className="absolute top-0 w-full h-full flex justify-end items-end p-6">
                      <h3 className="uppercase font-bold text-2xl group-hover:underline">{slide?.link?.title}</h3>
                    </div>
                  </LosseLink>
                </Slide>
              )
            })}
          </Slider>
          <div className="custom-sliderdefault-button-next px-4 py-3 hover:scale-105 transition-transform" role="button">
            <ArrowRounded />
          </div>
        </div>
      </div>
    </section>
  )
}
