import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import SearchDefault from './SearchDefault'

export default function SearchShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: SearchDefault
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
