import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import SliderDefault from './SliderDefault'
import SliderCustomers from './SliderCustomers'
import SliderGallery from './SliderGallery'

export default function SliderShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: SliderDefault,
    customers: SliderCustomers,
    gallery: SliderGallery
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
