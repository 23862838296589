import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Download from '~/components/svg/Download'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroVideo({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  return (
    <section id="HeroVideo" className="bg-black-knight text-white pt-10 pb-8 sm:pt-32 sm:pb-28">
      <div className="container flex flex-col items-center justify-center">
        <div className="relative w-full lg:w-2/3">
          <iframe src={fields.video || ''} title="DeliCoffee" className="aspect-video w-full h-full" frameBorder={0} allowFullScreen />
          <div className="sm:mt-10 flex flex-col sm:flex-row items-center justify-center sm:w-full">
            {fields.download && (
              <LosseLink className="btn btn-light mx-4 mt-6 sm:mt-0" to={fields.download?.mediaItemUrl}>
                <span className="flex items-center">
                  <Download className="mr-3" />
                  <Content>{fields.download?.description}</Content>
                </span>
              </LosseLink>
            )}
            {fields.cta?.link?.url && (
              <LosseLink to={fields.cta.link.url} className="btn btn-flush-orange mx-4 mt-4 sm:mt-0">
                <span className="flex items-center">
                  <LossePlaatjie src={fields.cta.icon} className="w-[25px] h-[25px] mr-3" maxwidth={25} />
                  <span>{fields.cta.link.title}</span>
                </span>
              </LosseLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
