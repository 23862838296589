import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import FormContact from './FormContact'
import FormTrial from './FormTrial'

export default function FormShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    contact: FormContact,
    trial: FormTrial
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
