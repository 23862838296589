import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import ArrowRounded from '~/components/svg/ArrowRounded'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'

export default function SliderCustomers({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  return (
    <section id="SliderCustomers" className="bg-black-knight text-white py-16">
      <div className="container py-16 bg-oil-rush">
        <Content className="content-lg">{fields?.description}</Content>
        <div className="flex items-center">
          <div className="custom-slidercustomers-button-prev px-4 py-3" role="button">
            <ArrowRounded className="rotate-180 hover:scale-105 transition-transform" />
          </div>
          <Slider
            mousewheel={{
              forceToAxis: true
            }}
            navigation={{
              prevEl: '.custom-slidercustomers-button-prev',
              nextEl: '.custom-slidercustomers-button-next'
            }}
            autoplay={{
              delay: 5000
            }}
            breakpoints={{
              0: {
                slidesPerView: 1
              }
            }}
            className="mt-8"
          >
            {fields?.slider?.map((slide, index) => {
              return (
                <Slide key={index}>
                  <Content className="content-lg lg:px-44">{slide?.description}</Content>
                </Slide>
              )
            })}
          </Slider>
          <div className="custom-slidercustomers-button-next px-4 py-3 hover:scale-105 transition-transform" role="button">
            <ArrowRounded />
          </div>
        </div>
      </div>
    </section>
  )
}
