import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import ServicesGrid from './ServicesGrid'

export default function ServicesShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    grid: ServicesGrid
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
