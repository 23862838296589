import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import Highlighted from './Highlighted'
import Overview from './Overview'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: Overview,
    highlighted: Highlighted
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
