import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import HeaderTopmenu from './HeaderTopmenu'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <header
      className={`fixed left-0 top-0 right-0 z-[99] border-b-[1px] border-transparent transition-all text-white${
        isSticky ? ' shadow-md border-flush-orange' : ''
      }`}
    >
      <HeaderTopmenu />
      <HeaderDesktop />
      <HeaderMobile />
    </header>
  )
}
