export default function Arrow({ className = '', type = 'light' }: { className?: string; type?: 'light' | 'dark' | 'flush-orange' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none" viewBox="0 0 21 21" className={className}>
      <g clipPath="url(#clip0_205_83)">
        <path
          className={`${type === 'light' ? 'fill-white' : type === 'flush-orange' ? 'fill-flush-orange' : 'fill-black-knight'}`}
          d="M1.262 11.974c.086.014.173.02.26.02h14.815l-.323.15c-.316.149-.603.352-.849.6L11.011 16.9a1.555 1.555 0 00-.218 1.99 1.503 1.503 0 002.254.196l7.512-7.512a1.503 1.503 0 00.002-2.125l-.002-.002-7.512-7.512a1.503 1.503 0 00-2.254.15 1.555 1.555 0 00.218 1.99l4.147 4.163c.22.22.474.406.751.548l.45.203H1.606A1.555 1.555 0 00.02 10.25c-.133.82.423 1.591 1.242 1.724z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_205_83">
          <path
            className={`${type === 'light' ? 'fill-white' : type === 'flush-orange' ? 'fill-flush-orange' : 'fill-black-knight'}`}
            d="M0 0H21V21H0z"
            transform="rotate(-180 10.5 10.5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}
