import Content from '~/components/elements/Content'
import { LosseLink } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Arrow from '~/components/svg/Arrow'

export default function BannerVideo({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section id="BannerVideo" className="bg-black-knight text-white pb-12">
      <div className="container">
        <div className="grid grid-cols-2 items-center md:gap-x-7">
          <div className="col-span-2 md:col-span-1 md:order-last">
            <div className="relative aspect-video">
              <iframe
                src={`${fields.vimeo}&autoplay=1&muted=1` || ''}
                title="Banner video"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              />
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 self-center md:order-first">
            <Content className="content-xxl max-lg:children-p:!pr-0 mt-4 mb-2 md:mb-12 md:mt-12 md:pt-0">{fields?.description}</Content>
            {fields.link?.title && (
              <LosseLink to={fields.link.url} className="btn btn-flush-orange">
                <span className="flex items-center">
                  <span>{fields.link.title}</span>
                  <Arrow className="ml-3" />
                </span>
              </LosseLink>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
