import React from 'react'
import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'

// Components
import HeroDefault from './HeroDefault'
import HeroAchievements from './HeroAchievements'
import HeroContact from './HeroContact'
import HeroVideo from './HeroVideo'
import HeroCoffee from './HeroCoffee'

export default function HeroShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: HeroDefault,
    achievements: HeroAchievements,
    contact: HeroContact,
    video: HeroVideo,
    coffee: HeroCoffee
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
