export default function Download({ className }: { className?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className={className}>
      <g fill="#fff" clipPath="url(#clip0_341_2563)">
        <path d="M12.032 19a2.992 2.992 0 002.122-.878l3.92-3.922-1.415-1.41-3.633 3.634L13 0h-2l.026 16.408-3.62-3.62L5.992 14.2l3.92 3.919a2.992 2.992 0 002.12.881z"></path>
        <path d="M22 16v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v5a3 3 0 003 3h18a3 3 0 003-3v-5h-2z"></path>
      </g>
      <defs>
        <clipPath id="clip0_341_2563">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}
