import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroDefault({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const colorClasses = ['text-jocular-green', 'text-irish-spring', 'text-white', 'text-silver-polish', 'text-young-fern']

  return (
    <section id="HeroDefault" className="bg-black-knight text-white pt-10 pb-8 sm:pt-14 sm:pb-12">
      <div className="container grid grid-cols-10 sm:gap-x-12">
        <div className="col-span-10 sm:col-span-5 lg:col-span-3 lg:mt-24">
          <Content className="content-lg">{fields?.description}</Content>
          <Content className="content-xl opacity-20 mt-7">{fields.quote}</Content>
        </div>
        <div className="col-span-10 sm:col-span-5 lg:col-span-4 lg:justify-self-end my-8 lg:my-0">
          <LossePlaatjie src={fields.image} className="max-w-[240px] mx-auto lg:max-w-none" />
        </div>
        <div className="col-span-10 lg:col-span-3 relative">
          {fields.usps?.map((usp: any, index: any) => (
            <div className="mb-10" key={index}>
              <h3 className={`${colorClasses[index]} uppercase text-base sm:text-xl font-bold mb-3`}>{usp?.title}</h3>
              <Content>{usp?.description}</Content>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
