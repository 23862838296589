/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { LosseLink, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import HeaderMenu from './HeaderMenu'
import Logo from '~/components/svg/Logo'
import MagnifyingGlass from '~/components/svg/MagnifyingGlass'

const HeaderMobile: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { pathname } = useLocation()
  const { isScrollable, setScrollable } = useLosseLayout()

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  }, [pathname])
  useEffect(() => {
    if (isOpen && isScrollable) {
      setScrollable(false)
    } else if (!isOpen && !isScrollable) {
      setScrollable(true)
    }
  }, [isOpen])

  return (
    <nav className="container-xl bg-black-knight flex justify-between items-center py-2 lg:hidden">
      <LosseLink aria-label="Home" to="/">
        <Logo className="w-[177px] h-[61px]" />
      </LosseLink>

      <div className="flex items-center">
        <LosseLink to="/zoeken/" className="block lg:hidden hover:opacity-80 mr-7">
          <MagnifyingGlass className="w-6 h-6" />
        </LosseLink>
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          className="z-10 h-12 w-8 text-center"
          aria-label="Open menu"
        >
          <div className="mx-auto my-[5px] h-[3px] w-full bg-white" />
          <div className="mx-auto my-[5px] h-[3px] w-full bg-white" />
          <div className="mx-auto my-[5px] h-[3px] w-full bg-white" />
        </button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-glass overflow-y-scroll"
            initial={{ y: '100vh' }}
            animate={{ y: '0vh' }}
            exit={{ y: '100vh' }}
            transition={{
              bounce: 0
            }}
          >
            <div
              className="absolute right-[2.25rem] top-[4.25rem] text-center"
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              aria-label="Close menu"
            >
              <div className="relative ml-4 h-8 w-8 before:absolute before:h-[33px] before:w-[2px] before:rotate-45 before:bg-white after:absolute after:h-[33px] after:w-[2px] after:-rotate-45 after:bg-white" />
            </div>
            <ul className="w-9/12 m-0 pl-0">
              <HeaderMenu />
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

export default HeaderMobile
