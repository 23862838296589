import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import Coffeebean from '~/components/svg/Coffeebean'
import CoffeebeanActive from '~/components/svg/CoffeebeanActive'
import type { Page_Flexcontent_Flex_Hero } from '~/graphql/types'

export default function HeroCoffee({ fields }: { fields: Page_Flexcontent_Flex_Hero }) {
  const { coffeeinfo } = fields
  let strength = [false, false, false, false, false]
  const coffeeRange = coffeeinfo?.strength || 0
  for (let i = 0; i < coffeeRange; i++) {
    strength[i] = true
  }

  return (
    <section id="HeroCoffee" className="relative bg-oil-rush text-white pt-10 pb-8 sm:pt-32 lg:pb-28">
      <div className="container grid grid-cols-6 gap-x-6 md:gap-x-32">
        <div className="col-span-6 sm:col-span-3 lg:col-span-2 bg-black-knight px-8 py-10">
          <h2 className="mb-5 font-bold text-2xl">{coffeeinfo?.title}</h2>
          <div className="my-3">
            <h3 className="font-bold mb-1">Soort</h3>
            <span>{coffeeinfo?.coffeetype}</span>
          </div>
          <div className="my-3"></div>
          <h3 className="font-bold mb-1">Smaak</h3>
          <span>{coffeeinfo?.taste}</span>
          <div className="my-3">
            <h3 className="font-bold mb-1">Sterkte</h3>
            <div className="flex">
              {strength.map((active, index) => {
                return (
                  <div className="relative w-[22px] h-[22px] mr-2" key={index}>
                    {active ? <CoffeebeanActive /> : <Coffeebean className="relative top-0 z-10 overflow-hidden" />}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3 lg:col-span-4 mt-8">
          <Content className="content-lg lg:pr-40">{fields.description}</Content>
        </div>
      </div>
      <div className="absolute left-0 right-0 bg-oil-rush h-[96px] -bottom-[96px] w-full" />
      <div className="lg:absolute lg:-bottom-1/2 right-0">
        <LossePlaatjie src={fields.image} />
      </div>
    </section>
  )
}
