/* eslint-disable react-hooks/exhaustive-deps */
import type { ReactElement } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import type { Coffee, Coffeemachine } from '~/graphql/types'
import type { Page_Flexcontent_Flex_Posts } from '~/graphql/types'
import { LosseBlogBink, LosseLink, LossePlaatjie, useLocation, useLosseBlogBink, useParams, useSearchParams } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import EndlessContainer from '~/components/elements/EndlessContainer'
import Slider, { Slide } from '~/components/elements/Slider'
import Pagination from '~/components/elements/Pagination'
import MostChosen from '~/components/elements/MostChosen'
import { motion, AnimatePresence } from 'framer-motion'
import { useInView } from 'react-intersection-observer'


export default function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const { search } = useLocation()
  const [searchParams] = useSearchParams()

  // @ts-ignore
  const __typename = fields.posts?.edges![0]?.node?.__typename
  let component: ReactElement
  let id: string
  switch (__typename?.toLowerCase()) {
    case 'coffeemachine':
      id = 'koffiemachine-overzicht'
      component = <CoffeemachineOverview fields={fields} id={id} />
      break
    default:
      id = 'koffie-overzicht'
      component = <CoffeeOverview fields={fields} id={id} />
      break
  }

  useEffect(() => {
    if (search) {
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView()
      }, 1)
    }
  }, [searchParams.get('_f')])

  if (!fields.posts?.pageInfo) {
    return
  }

  return (
    <section id={`Overview${__typename}`} className="bg-black-knight">
      <LosseBlogBink pageInfo={fields.posts?.pageInfo}>{component}</LosseBlogBink>
    </section>
  )
}

function CoffeemachineOverview({ fields, id }: { fields: Page_Flexcontent_Flex_Posts; id: string }) {
  const { hash } = useLocation()
  const blogBink = useLosseBlogBink()

  const brancheDatabaseId = fields.filters?.find((filter) => filter?.name === 'Branche')?.databaseId
  const merkDatabaseId = fields.filters?.find((filter) => filter?.name === 'Merk')?.databaseId
  const brancheFilters = fields.filters?.filter((filter) => filter?.parentDatabaseId === brancheDatabaseId)
  const merkFilters = fields.filters?.filter((filter) => filter?.parentDatabaseId === merkDatabaseId)

  useEffect(() => {
    if (hash) {
      const strippedHash = hash.replace('#', '')
      const activeFilter =
        brancheFilters?.filter((filter) => filter?.name?.toLowerCase() === strippedHash.toLowerCase())[0]?.databaseId ||
        merkFilters?.filter((filter) => filter?.name?.toLowerCase() === strippedHash.toLowerCase())[0]?.databaseId

      if (activeFilter) {
        // no fear
        setTimeout(() => {
          // is here
          blogBink.toggleFilter(activeFilter)
        }, 1)
      }
    }
  }, [hash])

  const [mobileFilterOpen, setMobileFilterOpen] = useState(false)
  const parentFilters = fields.filters?.filter((filter) => !filter?.parentDatabaseId)
  const { ref, inView } = useInView({
    threshold: 0.15
  })

  return (
    <div className="container pt-10" id={id}>
      <Content className="text-white text-4xl lg:text-6xl opacity-20">{fields.description}</Content>
      <div className="grid grid-cols-4">
        <div className="hidden md:block col-span-4 md:col-span-1 bg-white p-6">
          <span className="text-2xl font-bold">FILTEREN</span>
          {parentFilters?.map((parentFilter) => {
            const filters = fields.filters?.filter((filter) => filter?.parentDatabaseId === parentFilter?.databaseId)
            return (
              <div key={parentFilter?.databaseId}>
                <h3 className="text-lg font-bold my-6">{parentFilter?.name}</h3>
                {filters?.map((filter) => (
                  <div key={filter?.databaseId}>
                    <label className="flex items-center hover:opacity-80 hover:cursor-pointer">
                      <input
                        type="checkbox"
                        checked={blogBink.isFilterActive(filter?.databaseId)}
                        onChange={() => {
                          blogBink.toggleFilter(filter?.databaseId)
                        }}
                        className="appearance-none h-4 w-4 mt-[1px] mr-3 block rounded-sm bg-explosive-grey checked:bg-peach-fury checked:border-peach-fury hover:bg-flush-orange  focus:outline-peach-fury transition duration-200"
                      />
                      <span>{filter?.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            )
          })}
        </div>
        <div className="md:invisible absolute">
          <AnimatePresence key="button">
            {inView && (
              <motion.div
                className="md:invisible fixed bottom-0 left-0 right-0 w-full z-[99]"
                initial={{ height: 0 }}
                animate={{ height: 'fit-content' }}
                exit={{ height: 0 }}
                transition={{ bounce: 0 }}
              >
                <div
                  role="button"
                  className={`${mobileFilterOpen ? 'justify-between' : 'justify-center'
                    } flex justify-center items-center w-full bg-young-fern text-white px-7`}
                  onClick={() => {
                    setMobileFilterOpen(!mobileFilterOpen)
                  }}
                >
                  <div className="flex justify-center">
                    <span className="text-2xl font-bold py-3 text-center">FILTEREN</span>
                  </div>
                  {mobileFilterOpen && <div>TOEPASSEN</div>}
                </div>

                <AnimatePresence key="mobile-filters">
                  {mobileFilterOpen && (
                    <motion.div
                      initial={{ height: '0vh' }}
                      animate={{ height: '30vh' }}
                      exit={{ height: '0vh' }}
                      className="overflow-y-scroll bg-white px-4 pb-10"
                    >
                      {parentFilters?.map((parentFilter) => {
                        const filters = fields.filters?.filter((filter) => filter?.parentDatabaseId === parentFilter?.databaseId)
                        return (
                          <div key={parentFilter?.databaseId} className="group group-last:pb-10">
                            <h3 className="text-lg font-bold my-3">{parentFilter?.name}</h3>
                            {filters?.map((filter) => (
                              <div key={filter?.databaseId}>
                                <label className="flex items-center hover:opacity-80 hover:cursor-pointer">
                                  <input
                                    type="checkbox"
                                    checked={blogBink.isFilterActive(filter?.databaseId)}
                                    onChange={() => {
                                      blogBink.toggleFilter(filter?.databaseId)
                                      // setMobileFilterOpen(!mobileFilterOpen)
                                    }}
                                    className="appearance-none h-4 w-4 mt-[1px] mr-3 block rounded-sm bg-explosive-grey checked:bg-young-fern checked:border-young-fern hover:bg-irish-spring  focus:outline-young-fern transition duration-200"
                                  />
                                  <span>{filter?.name}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        )
                      })}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="col-span-4 sm:col-span-3 bg-cold-morning p-6" ref={ref}>
          <div className="grid grid-cols-6 gap-x-4 gap-y-6">
            {fields.posts?.edges?.map((edge) => {
              const post = edge?.node as Coffeemachine

              return (
                <div key={post.databaseId} className="relative col-span-6 md:col-span-3 lg:col-span-2 bg-white">
                  {post.recap?.mostchosen && <MostChosen className="top-7" />}
                  <div className="p-4 flex flex-col justify-between h-full items-center">
                    <LossePlaatjie
                      loading="eager"
                      src={post.recap?.thumbnail}
                      maxwidth={250}
                      className="h-32 md:h-60 lg:h-80 w-auto object-cover"
                    />
                    <h3 className="font-bold text-xl mb-4">{post.title}</h3>
                    <ul className="flex flex-col ">
                      {post?.recap?.usps?.map((usp, index) => (
                        <li key={index} className="flex items-center">
                          <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1454 0L5.01224 6.95307L1.99556 3.57052L0 5.5353L4.8736 11L14 2.11224L12.1454 0Z" fill="#FF6C01" />
                          </svg>
                          <span className="ml-2">{usp?.item}</span>
                        </li>
                      ))}
                    </ul>
                    <LosseLink to={fields.cta?.link?.url} className="btn btn-flush-orange w-4/5 mt-5">
                      <span className="flex items-center">
                        <LossePlaatjie src={fields.cta?.icon} className="mr-3" maxwidth={50} />
                        <span>{fields.cta?.link?.title}</span>
                      </span>
                    </LosseLink>
                    <LosseLink to={post.uri} className="btn btn-black-knight mt-3 w-4/5 text-center">
                      {fields.readmoretext}
                    </LosseLink>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex justify-center">
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  )
}

function CoffeeOverview({ fields, id }: { fields: Page_Flexcontent_Flex_Posts; id: string }) {
  const posts = fields?.posts?.edges || []

  if (!posts) {
    return null
  }

  let taxonomies: Array<{ databaseId: number; name: string }> = []
  posts.forEach((edge) => {
    const post = edge?.node as Coffee
    if (!post) {
      return
    }
    post.coffeetypes?.edges?.forEach((edge) => {
      if (!edge?.node?.name || !edge.node.databaseId) {
        return
      }
      const taxonomy = {
        databaseId: edge.node.databaseId,
        name: edge.node.name
      }

      if (taxonomies.some((t) => t.databaseId === taxonomy.databaseId)) {
        return
      }
      taxonomies.push(taxonomy)
    })
  })

  return (
    <div className="text-white" id={id}>
      <EndlessContainer>
        {taxonomies
          .sort((a, b) => (a?.databaseId || 0) - (b?.databaseId || 0))
          .map((taxonomy, index) => (
            <div key={index} className="pb-8 sm:pb-20">
              <h2 className="text-white text-3xl sm:text-6xl uppercase font-bold opacity-20 py-6 sm:py-10">{`Onze ${taxonomy.name}`}</h2>
              <Slider
                mousewheel={{
                  forceToAxis: true
                }}
                autoplay={false}
                breakpoints={{
                  0: {
                    slidesPerView: 1.9,
                    spaceBetween: 20
                  },
                  1024: {
                    slidesPerView: 3.9,
                    spaceBetween: 20
                  }
                }}
                className="pb-28"
              >
                {posts
                  .filter((edge) => {
                    const post = edge?.node as Coffee
                    return post.coffeetypes?.edges?.some((edge) => edge?.node?.databaseId === taxonomy.databaseId)
                  })
                  .map((edge) => {
                    const post = edge?.node as Coffee
                    return (
                      <Slide key={index} className="group relative h-auto  py-10">
                        <div className="absolute -top-1/4 -left-1/4 w-[150%] h-[150%] rounded-full bg-gradient-radial from-[#363B45] via-transparent" />

                        {post.recap?.mostchosen && <MostChosen className="top-0 sm:top-auto z-[2]" />}
                        <LosseLink to={post.uri} className="relative">
                          <LossePlaatjie
                            className="w-full h-full mx-auto group-hover:scale-125 transition-transform"
                            src={post?.recap?.thumbnail}
                            maxwidth={1999}
                          />
                        </LosseLink>
                      </Slide>
                    )
                  })}
              </Slider>
            </div>
          ))}
      </EndlessContainer>
    </div>
  )
}
