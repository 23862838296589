import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import Loading from '~/components/elements/Loading'
import Form from '~/components/elements/Form'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function FormTrial({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  return (
    <section id="FormTrial" className="bg-black-knight pb-48 text-white">
      <div className="container">
        <div className="grid grid-cols-5 gap-x-12">
          <div className="col-span-5 sm:col-span-2 md:-mr-8 lg:-mr-44 sm:order-last">
            <LossePlaatjie src={fields.image} maxwidth={1000} />
          </div>
          <div className="col-span-5 sm:col-span-3 self-center sm:order-first">
            <Content className="content-lg mb-12 mt-12 sm:pt-0 md:children-p:pr-40">{fields?.description}</Content>
            <div className="relative bg-white py-6 sm:py-14 px-4 sm:px-16 lg:mr-40">
              <Form
                data={fields.form}
                renderLoading={() => <Loading />}
                renderStatus={({ status, content }) => (
                  <div>
                    <Content className="text-black">{content}</Content>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
